// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'

import map from 'lodash/map'
import kebabCase from 'lodash/kebabCase'

import slice from 'lodash/slice'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { graphql } from 'gatsby'

import Row from 'antd/lib/row'
import 'antd/lib/row/style/css'

import Col from 'antd/lib/col'
import 'antd/lib/col/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Breadcrumbs from '../breadcrumbs'
import '../breadcrumbs/style.less'

import Link from '../link'
import BlogPageWrapper from '../blog-page-wrapper'

import getRandomArbitraryInt from '../../methods/getRandomArbitraryInt'

import './style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const pageQuery = graphql`
  query BlogPageTemplateQuery($routeSlug: String) {
    allBlogJson(filter: { routeSlug: { eq: $routeSlug } }) {
      edges {
        node {
          title
          routeSlug
          publishedTimestamp
          contentBlocks {
            type
            content
          }
        }
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
class Page extends React.PureComponent {
  /** [constructor description] */
  constructor() {
    super()
  }

  /** [render description] */
  render() {
    const {
      data: {
        allBlogJson: {
          edges: [
            {
              node: { title, routeSlug, publishedTimestamp, contentBlocks },
            },
          ],
        },
      },
      pageContext: { next, prev, humanDate },
    } = this.props

    const dateTime = new Date(publishedTimestamp * 1000).toLocaleDateString(
      'en-US'
    )

    const pageSchema = {
      title,
      slug: routeSlug.substring(1),
      abstract: title,
      breadcrumbs: [
        { title: 'Homepage', slug: '' },
        { title: 'Blog', slug: '/blog' },
        { title, slug: routeSlug.substring(1) },
      ],
    }

    const now = Math.floor(new Date().getTime() / 1000)

    const contentBlocks1 = slice(contentBlocks, 0, contentBlocks.length / 2)
    const contentBlocks2 = slice(contentBlocks, contentBlocks.length / 2)

    return (
      <BlogPageWrapper
        className="blog-page"
        pageSchema={pageSchema}
        {...this.props}
      >
        <Breadcrumbs
          breadcrumbs={pageSchema.breadcrumbs}
          prev={prev}
          next={next}
        />
        <h1>{title}</h1>
        <Row
          gutter={[
            { xs: 8, sm: 16, md: 24, lg: 32, xl: 32, xxl: 32 },
            { xs: 8, sm: 16, md: 24, lg: 32, xl: 32, xxl: 32 },
          ]}
        >
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            {map(contentBlocks1, (block) => {
              const { type, content } = block
              let returnThis = <Fragment />

              if (type === 'text') {
                returnThis = <p>{content}</p>
              }

              if (type === 'title') {
                returnThis = <h2 id={kebabCase(content)}>{content}</h2>
              }

              return returnThis
            })}
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            {map(contentBlocks2, (block) => {
              const { type, content } = block
              let returnThis = <Fragment />

              if (type === 'text') {
                returnThis = <p>{content}</p>
              }

              if (type === 'title') {
                returnThis = <h2 id={kebabCase(content)}>{content}</h2>
              }

              return returnThis
            })}
          </Col>
        </Row>
      </BlogPageWrapper>
    )
  }
}

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- State
// ----------------------------------------------------------------------------
const withState = connect(
  (state) => ({
    headerState: state.headerState,
  })
  // dispatch => ({
  //   updateHeaderState(payload) {
  //     dispatch(updateHeaderState(payload))
  //   },
  // })
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedPage = compose(
  withState // Add state
)(Page)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default ComposedPage
