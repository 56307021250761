// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import kebabCase from 'lodash/kebabCase'

import classNames from 'classnames'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { StaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import Row from 'antd/lib/row'
import 'antd/lib/row/style/css'

import Col from 'antd/lib/col'
import 'antd/lib/col/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Link from '../link'
import '../link/style.less'

import StandardPageWrapper from '../standard-page-wrapper'
import '../standard-page-wrapper/style.less'

import './style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query BlogPageWrapperQuery {
    allBlogJson {
      edges {
        node {
          title
          routeSlug
          publishedTimestamp
          cover {
            childImageSharp {
              gatsbyImageData(
                width: 600
                placeholder: TRACED_SVG
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page Wrapper */
const PageWrapper = ({ children, className, ...rest }) => (
  <StaticQuery
    query={query}
    render={(data) => {
      const {
        allBlogJson: { edges },
      } = data

      const nodes = map(edges, 'node')
      const ordered = orderBy(nodes, ['publishedTimestamp', 'asc'])

      const {
        location: { hash },
        pageContext: {
          intl: { originalPath },
        },
      } = rest

      return (
        <StandardPageWrapper
          className={classNames(className, 'blog-page', 'explorer')}
          {...rest}
        >
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              {children}
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <aside
                style={{
                  borderTop: '4px solid var(--orange1)',
                  marginTop: '1.1rem',
                  padding: '1.1rem',
                  background: 'var(--gray2)',
                }}
              >
                <h2>Other posts...</h2>
                <Row
                  gutter={{ xs: 8, sm: 16, md: 16, lg: 16, xl: 16, xxl: 16 }}
                >
                  {map(ordered, ({ title, routeSlug, cover }) => (
                    <Col xs={12} sm={8} md={6} lg={6} xl={6} xxl={6}>
                      <Link
                        className={classNames('post')}
                        to={routeSlug}
                        style={{ display: 'block', marginBottom: '0.55rem' }}
                      >
                        <GatsbyImage image={getImage(cover)} />
                        <h4
                          style={{
                            padding: '0.7255rem 0.55rem',
                            marginBottom: 0,
                          }}
                        >
                          {title}
                        </h4>
                      </Link>
                    </Col>
                  ))}
                </Row>
              </aside>
            </Col>
          </Row>
        </StandardPageWrapper>
      )
    }}
  />
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default PageWrapper
