// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

import map from 'lodash/map'
import isNull from 'lodash/isNull'
import isUndefined from 'lodash/isUndefined'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Link from '../link'
import '../link/style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Breadcrumbs */
const Breadcrumbs = ({ breadcrumbs, prev = null, next = null }) => (
  <div className="breadcrumbs">
    <p className="breadcrumb">
      {map(breadcrumbs, (breadcrumb, index) => (
        <Fragment>
          <Link to={breadcrumb.slug}>{breadcrumb.title}</Link>
          {index !== breadcrumbs.length - 1 && (
            <span>&nbsp;&nbsp;⊳&nbsp;&nbsp;</span>
          )}
        </Fragment>
      ))}
    </p>
    <div className="prevnext">
      {isNull(prev) === false && (
        <p className="prev">
          <Link to={prev.routeSlug}>&nbsp;&nbsp;⊲⊲&nbsp;&nbsp;</Link>
        </p>
      )}
      {isNull(next) === false && (
        <p className="next">
          <Link to={next.routeSlug}>&nbsp;&nbsp;⊳⊳&nbsp;&nbsp;</Link>
        </p>
      )}
    </div>
  </div>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Breadcrumbs
